import React from 'react';
import { ReactComponent as Search } from '../../../assets/Images/search.svg';
import { Typography } from '@mui/material';
import styles from './EmptyResult.module.scss';

export const EmptyResult = () => {
  return (
    <div className={styles.EmptyResultWrapper}>
      <Search />
      <Typography>По вашему запросу ничего не найдено</Typography>
    </div>
  );
};
