import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import styles from './SearchControl.module.scss';

export const SearchControl = ({
  label,
  searchText,
  onSearch
}) => {

  const [search, setSearch] = useState('');

  const handleOnSearch = () => {
    onSearch(search);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOnSearch();
    }
  };

  useEffect(() => {
    setSearch(searchText);
  }, [searchText]);

  return (
    <div className={styles.SearchWrapper}>
      <TextField
        type="search"
        fullWidth
        label={label}
        inputProps={{'maxLength': 50}}
        className={styles.SearchInput}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(e) => handleOnKeyDown(e)}
      />
      <Button
        color="success"
        variant="outlined"
        onClick={handleOnSearch}
      >
        Найти
      </Button>
    </div>
  );
}