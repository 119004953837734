import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getOneCompany } from '../../api/CompanyApi';

import { URLS } from '../../utils/urls.util';
import { useApi } from '../../hooks/useApi';

import { getAppSettings } from '../../api/AppSettings';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { SingleCompanyNavigation } from './SingleCompanyNavigation';
import { CompanyDetailsForm } from '../CompanyDetailsForm/CompanyDetailsForm';

export const SingleCompanyDetails = ({ permission }) => {
  const getOneCompanyApi = useApi(getOneCompany);
  const getAppSettingsApi = useApi(getAppSettings);
  const [wasChange, setChange] = useState(false);

  const [company, setCompany] = useState({});
  const [appSettings, setAppSettings] = useState({});

  const { id } = useParams();

  useEffect(() => {
    if (id !== 'create') {
      getOneCompanyApi
        .sendRequest(id, ['meta'])
        .then((company) => setCompany(company));
    }
    getAppSettingsApi.sendRequest().then((result) => setAppSettings(result));
  }, []);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.COMPANIES}>
      Организации
    </Link>,
    <Typography key="3" color="text.primary">
      {company.title}
    </Typography>,
    <Typography key="3" color="text.primary">
      Реквизиты
    </Typography>,
  ];

  return (
    <FullPageLayout
      obj={company}
      seoTitle={'Реквизиты - ' + company?.title}
      pageTitle={company.title}
      isCreated={false}
      breadcrumbs={breadcrumbs}
      showHiddenData={true}
    >
      <SingleCompanyNavigation
        company={company}
        activeTab={2}
        wasChange={wasChange}
        appSettings={appSettings}
      />

      <CompanyDetailsForm
        permission={permission}
        company={company}
        appSettings={appSettings}
        wasChangeHandler={(data) => setChange(data)}
        afterUpdateHandler={(data) => setCompany(data)}
      />
    </FullPageLayout>
  );
};
