import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  deleteCompany,
  getDefaultRates,
  saveNewCompany,
  updateCompany,
} from '../../api/CompanyApi';
import { setAlert } from '../../store/alert.store';
import { URLS } from '../../utils/urls.util';

import InputMask from 'react-input-mask';
import { FormValidator } from '../FormValidator/FormValidator';
import { CompanyValidationConfig } from '../../utils/formValidatorConfigs/config';
import { useApi } from '../../hooks/useApi';
import { getAddressData } from '../../api/DaDataApi';
import styles from './CompanyForm.module.scss';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';

export const CompanyForm = ({
  isCreate,
  company,
  appSettings,
  wasChangeHandler,
  afterUpdateHandler,
  permission,
  prevPage,
  ...props
}) => {
  const saveNewCompanyApi = useApi(saveNewCompany);
  const updateCompanyApi = useApi(updateCompany);
  const deleteCompanyApi = useApi(deleteCompany);
  const getAddressDataApi = useApi(getAddressData);
  const getDeafultRatesApi = useApi(getDefaultRates);
  const [companyState, setCompanyState] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const [validateErrors, setValidateErrors] = useState([]);
  const [addressHelpList, setAddressHelpList] = useState([]);
  const [defaultRates, setDefaultRates] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [wasChange, setChange] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);

  const config = useMemo(
    () =>
      CompanyValidationConfig({
        oneOfList: [],
        customUniq: [],
      }),
    [],
  );

  useEffect(() => {
    let inputs =
      document.forms['company-form-name'].getElementsByTagName('input');
    let elements = Array.prototype.slice.call(inputs);
    if (elements.length) {
      elements = elements.filter((i) => i.name);
      const array = elements.map((i) => ({
        required: i.required,
        value: i.value,
      }));

      setFormDisabled(array.some((i) => i.required && !i.value));
    }
  }, [companyState, validateErrors]);

  const handlerSaveNewCompany = async () => {
    try {
      const c = await saveNewCompanyApi.sendRequest(companyState);
      wasChangeHandler(false);
      navigate(URLS.COMPANIES + '/' + c.id + '/data');
      dispatch(setAlert({ status: 201, text: 'Организация добавлена' }));
    } catch (error) {}
  };

  const handlerUpdateCompany = async () => {
    try {
      const c = await updateCompanyApi.sendRequest(companyState);
      wasChangeHandler(false);
      afterUpdateHandler(c);
      dispatch(setAlert({ status: 200, text: 'Организация обновлена' }));
    } catch (error) {}
  };

  const handleDeleteCompany = async () => {
    await deleteCompanyApi.sendRequest({
      id: companyState.id,
    });
    navigate(prevPage);
    dispatch(setAlert({ status: 200, text: 'Организация удалена' }));
  };

  useEffect(() => {
    setCompanyState(company);
    getDeafultRatesApi.sendRequest()
      .then((result) => setDefaultRates(result))
      .catch((err) => console.error(err));
  }, [company]);

  const changeMetaData = (meta, key, value) => {
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    }
    const metaItem = meta?.find((m) => m.key === key);
    if (metaItem) {
      meta = meta?.map((m) => {
        if (m.key === key) {
          m.value = value;
        }
        return m;
      });
    } else {
      meta.push({ key, value });
    }

    return meta;
  };

  const formControlHandler = (target, key) => {
    if (!wasChange) {
      setChange(true);
      wasChangeHandler(true);
    }
    let value = target.value;
    setCompanyState({ ...companyState, [key]: value });
  };
  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const onChangeMetaInput = (target, name) => {
    if (!wasChange) {
      setChange(true);
      wasChangeHandler(true);
    }
    setCompanyState({
      ...companyState,
      meta: changeMetaData(companyState?.meta || [], name, target.value),
    });
  };

  const clickAddressHelper = (address) => {
    onChangeMetaInput({ value: address.unrestricted_value }, 'ActualAddress');
    setAddressHelpList([]);
  };

  const fieldInObject = (name) =>
    !!companyState?.meta?.find((item) => item.key === name);

  return (
    <div {...props} style={{ paddingBottom: '40px' }}>
      <FormValidator
        name="company-form-name"
        validationConfig={config}
        changed={companyState}
        setErrorsHandler={(data) => handlerError(data)}
        fieldInObject={fieldInObject}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              as="p"
              variant="p"
              sx={{ mt: 4, mb: 3, color: '#949494', fontSize: '16px' }}
            >
              Данные будут отображаться в АРМ.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Tooltip title="Название должно быть уникально в рамках всей системы">
              <TextField
                fullWidth
                label={
                  <span className={styles.CompanyForm__Text_field_required}>
                    <span>Наименование организации</span> <span> *</span>
                  </span>
                }
                variant="outlined"
                value={companyState?.title}
                name="title"
                required={config.title.isRequired}
                error={validateErrors?.title?.text}
                helperText={validateErrors?.title?.text}
                className={styles.CompanyForm__Text_field}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target }) => formControlHandler(target, 'title')}
                disabled={!permission?.edit}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyForm__Text_field_required}>
                  <span>Полный адрес</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="ActualAddress"
              required={config?.ActualAddress?.isRequired}
              error={validateErrors?.ActualAddress?.text}
              helperText={validateErrors?.ActualAddress?.text}
              className={styles.CompanyForm__Text_field}
              value={
                companyState?.meta?.find((m) => m.key == 'ActualAddress')?.value
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChangeMetaInput(target, 'ActualAddress')
              }
              onInput={({ target }) => {
                getAddressDataApi
                  .sendRequest({ query: target.value })
                  .then((result) => setAddressHelpList(result));
              }}
              disabled={!permission?.edit}
            />
            <Card
              sx={{
                position: 'absolute',
                zIndex: 3,
                mt: '-30px',
                background: '#fff',
              }}
            >
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {addressHelpList?.map((address) => (
                  <li
                    className={styles.AddressList__Item}
                    onClick={() => clickAddressHelper(address)}
                  >
                    {address.unrestricted_value}
                  </li>
                ))}
              </ul>
            </Card>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Typography as="p" variant="h5" sx={{ mt: 4, mb: 3 }}>
              Контактное лицо
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyForm__Text_field_required}>
                  <span>Фамилия</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="ContactLastName"
              required={config?.ContactLastName?.isRequired}
              error={validateErrors?.ContactLastName?.text}
              helperText={validateErrors?.ContactLastName?.text}
              className={styles.CompanyForm__Text_field}
              value={
                companyState?.meta?.find((m) => m.key == 'ContactLastName')
                  ?.value
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChangeMetaInput(target, 'ContactLastName')
              }
              disabled={!permission?.edit}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyForm__Text_field_required}>
                  <span>Имя</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="ContactFirstName"
              required={config?.ContactFirstName?.isRequired}
              error={validateErrors?.ContactFirstName?.text}
              helperText={validateErrors?.ContactFirstName?.text}
              className={styles.CompanyForm__Text_field}
              value={
                companyState?.meta?.find((m) => m.key == 'ContactFirstName')
                  ?.value
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChangeMetaInput(target, 'ContactFirstName')
              }
              disabled={!permission?.edit}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyForm__Text_field_required}>
                  <span>Отчество</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="ContactMiddleName"
              required={config?.ContactMiddleName?.isRequired}
              error={validateErrors?.ContactMiddleName?.text}
              helperText={validateErrors?.ContactMiddleName?.text}
              className={styles.CompanyForm__Text_field}
              value={
                companyState?.meta?.find((m) => m.key == 'ContactMiddleName')
                  ?.value
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChangeMetaInput(target, 'ContactMiddleName')
              }
              disabled={!permission?.edit}
            />
          </Grid>
          <Grid item xs={4}>
            <InputMask
              mask="+7(999)999-99-99"
              value={
                companyState?.meta?.find((m) => m.key === 'ContactPhone')?.value
              }
              fullWidth
              label={
                <span className={styles.CompanyForm__Text_field_required}>
                  <span>Телефон</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="ContactPhone"
              required={config?.ContactPhone?.isRequired}
              error={validateErrors?.ContactPhone?.text}
              helperText={validateErrors?.ContactPhone?.text}
              className={styles.CompanyForm__Text_field}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                permission?.edit && onChangeMetaInput(target, 'ContactPhone')
              }
              disabled={!permission?.edit}
            >
              {(props) => (
                <TextField
                  {...props}
                  disabled={
                    !(permission?.create && !companyState.id) &&
                    !permission?.edit
                  }
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={
                <span className={styles.CompanyForm__Text_field_required}>
                  <span>Email</span>
                  <span> *</span>
                </span>
              }
              variant="outlined"
              name="ContactEmail"
              required={config?.ContactEmail?.isRequired}
              error={validateErrors?.ContactEmail?.text}
              helperText={validateErrors?.ContactEmail?.text}
              className={styles.CompanyForm__Text_field}
              value={
                companyState?.meta?.find((m) => m.key == 'ContactEmail')?.value
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                onChangeMetaInput(target, 'ContactEmail')
              }
              disabled={!permission?.edit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography as="p" variant="h5" sx={{ mt: 4, mb: 3 }}>
              Тариф организации
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                defaultValue="outlined"
                name="tariffVersion"
                sx={{ width: '100%', flexWrap: 'nowrap' }}
                disabled={!permission?.edit}
                value={companyState?.Version + '' || '1'}
                onChange={({ target: { value } }) => {
                  if (!wasChange) {
                    setChange(true);
                    wasChangeHandler(true);
                  }
                  setCompanyState({ ...companyState, Version: value });
                }}
              >
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{
                    height: companyState?.rates?.length * 57 + 57 + 'px',
                  }}
                >
                  <Box sx={{ border: 'solid 1px #CCCCCC', width: '40%' }}>
                    <Box sx={{ p: 2, borderBottom: 'solid 1px #cccccc' }}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Функции
                      </Typography>
                    </Box>
                    <Box sx={{ p: 2, borderBottom: 'solid 1px #cccccc' }}>
                      <Typography>Количество объектов</Typography>
                    </Box>
                    <Box sx={{ p: 2, borderBottom: 'solid 1px #cccccc' }}>
                      <Typography>Количество квартир</Typography>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <Typography>
                        Количество активных сотрудников в АРМ
                      </Typography>
                    </Box>
                  </Box>
                  {(companyState?.rates?.length
                    ? companyState?.rates
                    : defaultRates
                  )?.map((rate, index) => (
                    <Box
                      key={rate?.id || index}
                      sx={{
                        border: `solid 1px ${
                          companyState.Version == index && permission?.edit
                            ? '#1976D2'
                            : '#CCCCCC'
                        }`,
                        width: '20%',
                        background:
                          companyState.Version == index && permission?.edit
                            ? 'rgba(25, 118, 210, 0.1) !important'
                            : 'none',
                      }}
                    >
                      <Box
                        sx={{
                          p: 2,
                          borderBottom: 'solid 1px #cccccc',
                        }}
                      >
                        <FormControlLabel
                          value={index}
                          control={
                            <Radio
                              sx={{ py: 0 }}
                              disabled={!permission?.edit}
                            />
                          }
                          label={<b>{rate.name}</b>}
                        />
                      </Box>
                      <Box
                        sx={{
                          p: 2,
                          borderBottom: 'solid 1px #cccccc',
                          maxHeight: '57px',
                        }}
                      >
                        <Typography>
                          {rate.objectCount === null
                            ? 'без ограничений'
                            : rate.objectCount}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          p: 2,
                          borderBottom: 'solid 1px #cccccc',
                          maxHeight: '57px',
                        }}
                      >
                        <Typography>
                          {rate.flatCount === null
                            ? 'без ограничений'
                            : rate.flatCount}
                        </Typography>
                      </Box>
                      <Box sx={{ p: 2, maxHeight: '57px' }}>
                        <Typography>
                          {rate.employeeCount === null
                            ? 'без ограничений'
                            : rate.employeeCount}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ mb: 1, mt: 3 }} variant="h5">
            Оплата
          </Typography>
          <Typography sx={{ color: '#949494' }}>
            Для включения оплат необходимо заполнить раздел «Реквизиты»{' '}
          </Typography>

          <FormControlLabel
            sx={{ mt: 1 }}
            control={
              <Switch
                checked={companyState?.paymentEnable}
                defaultChecked={companyState?.paymentEnable}
                disabled={!permission.edit || !companyState?.shopCode}
                onChange={({ target }) => {
                  setCompanyState({
                    ...companyState,
                    paymentEnable: target.checked,
                  });
                  setChange(true);
                  wasChangeHandler(true);
                }}
              />
            }
            label="Подключение оплат"
          />
        </Grid>

        <FooterButtonBar
          showDeleteButton={company.id && permission?.delete}
          cancelButtonHandler={() => navigate(URLS?.COMPANIES)}
          showCancelButton={true}
          updateButtonHandler={handlerUpdateCompany}
          showUpdateButton={permission?.edit && company?.id}
          disabledUpdateButton={
            (wasChange ? Object.keys(validateErrors).length : 1) ||
            !permission?.edit ||
            !companyState?.Version
          }
          showCreateButton={permission?.create && !company?.id}
          createButtonHandler={handlerSaveNewCompany}
          disabledCreateButton={
            formDisabled || !permission?.create || !companyState?.Version
          }
          deleteButtonHandler={() => setOpenApprove(true)}
          loading={saveNewCompanyApi.loading || updateCompanyApi.loading}
        />
      </FormValidator>
      <ActionApproveV2
        target={company}
        open={openApprove}
        title={`Вы действительно хотите удалить организацию и все связанные с ней сущности?`}
        handleClose={() => setOpenApprove(false)}
        handleSuccess={() => {
          handleDeleteCompany();
          setOpenApprove(false);
        }}
        showDescription={false}
      />
    </div>
  );
};
